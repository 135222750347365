import Image from "next/image";
import {AssetOverlay, OverlayPosition} from "../types";

function positionToStyle(
    position: OverlayPosition,
    type: "details" | "thumbnail",
    padding: number,
    horizontalMargin: number,
    verticalMargin: number,
): React.CSSProperties {
    const style: React.CSSProperties = {
        position: 'absolute',
        padding: `${padding}px`,
        margin: `${verticalMargin}px ${horizontalMargin}px`

    };

    switch (position) {
        case 'top-left':
            style.top = type == 'details' ? '10px' : '5px';
            style.left = type == 'details' ? '0px' : `5px`;
            break;
        case 'top-center':
            style.top = type == 'details' ? '10px' : `5px`;
            style.left = '50%';
            style.transform = 'translateX(-50%)';
            break;
        case 'top-right':
            style.top = type == 'details' ? '10px' : `5px`;
            style.right = type == 'details' ? '0px' : `5px`;
            break;
        case 'middle-left':
            style.top = '50%';
            style.left = type == 'details' ? '0px' : `5px`;
            style.transform = 'translateY(-50%)';
            break;
        case 'middle-center':
            style.top = '50%';
            style.left = '50%';
            style.transform = 'translate(-50%, -50%)';
            break;
        case 'middle-right':
            style.top = '50%';
            style.right = type == 'details' ? '0px' : `5px`;
            style.transform = 'translateY(-50%)';
            break;
        case 'bottom-left':
            style.bottom = type == 'details' ? '10px' : `5px`;
            style.left = type == 'details' ? '0px' : `5px`;
            break;
        case 'bottom-center':
            style.bottom = type == 'details' ? '10px' : `5px`;
            style.left = '50%';
            style.transform = 'translateX(-50%)';
            break;
        case 'bottom-right':
            style.bottom = type == 'details' ? '10px' : `5px`;
            style.right = type == 'details' ? '0px' : `5px`;
            break;
        default:
            // Handle any additional or invalid positions
            break;
    }

    return style;
}

export default function ImageOverlays({
                                          overlays = [],
                                          type = "details",
                                      }: {
    type: "details" | "thumbnail";
    overlays: AssetOverlay[];
}) {
    return (
        <>
            {overlays.map((overlay, index) => {
                return (
                    <Image
                        key={index}
                        className={
                            `${type === 'details'
                                ? 'w-[10vw] h-[10vw] md:w-[12vw] md:h-[12vw] lg:w-[8vw] lg:h-[8vw] max-w-[130px] max-h-[130px] min-w-[80px] min-h-[80px]'
                                : 'w-[8vw] h-[8vw] md:w-[6vw] md:h-[6vw] lg:w-[4vw] lg:h-[4vw] max-w-[60px] max-h-[60px] min-w-[50px] min-h-[50px]'
                            }
                 p-${overlay?.padding ?? 0} m-x-${overlay?.horizontalMargin ?? 0} m-y-${overlay?.verticalMargin ?? 0}
               `
                        }
                        style={{
                            ...positionToStyle(
                                overlay.position,
                                type,
                                overlay?.padding ?? 0,
                                overlay?.horizontalMargin ?? 0,
                                overlay?.verticalMargin ?? 0,
                            ),
                            height: '15%',
                            width: 'auto',
                            maxHeight: '15%', // ensure image doesn't overflow the container
                            maxWidth: '15%', // ensure image fits within the container
                            zIndex: 10,
                            objectFit: 'contain',
                        }}
                        height={100}
                        width={100}
                        src={overlay.image.url}
                        alt={overlay.title}
                        loading="eager"
                        priority={true}
                    />
                );
            })}
        </>
    );
}
